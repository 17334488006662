@import "bourbon";
@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px) {
	/**/

	.main_header_middle {
		h1 {
			font-size: 42px;
			line-height: 48px; } }

	.info_icons_block {
		.item {
			.item_descr {
				font-size: 16px; } } }



	section.sect_about {
		padding-top: 40px; }

	.about_section_item {
		padding: 30px 0;
		.item_bg_img {
			display: none; }
		&:first-child {
			.item_text {
				font-size: 18px;
				line-height: 24px;
				margin-top: 0px; } } }


	.about_section_item {
		&:nth-child(even) {
			.about_section_item_content {
				.item_text {
					order: 1; }
				.item_img {
					order: 2; } } } }

	.about_section_item_content {
		flex-direction: column;
		.item_text {
			width: 100%; }
		.item_img {
			width: 100%;
			margin-top: 10px;
			text-align: center; }
		> div:first-child {
			padding-right: 0; } }


	.no-flexbox {
		.about_section_item_content {
			display: block;
			font-size: 0;
			> div {
				display: block;
				width: 100%;
				vertical-align: top;
				&:first-child {
					padding-right: 0px; } }
			.item_img {
				margin: 25px 0; } } } }

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
	/**/

	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important; }

	.about_section_item {
		.item_img {
			opacity: 1 !important; } }

	.info_icons_block {
		.item {
			opacity: 1 !important; } }

	h1 {
		opacity: 1 !important; }

	.about_section_item_content {
		.item_text {
			opacity: 1 !important; } }


	.section_head {
		font-size: 40px;
		line-height: 44px;
		margin-bottom: 40px; }


	.header_contacts_info {
		.header_contacts_phone {
			font-size: 18px; }
		.header_contacts_email {
			font-size: 16px; } }

	.logo_block {
		.logo_text {
			> span {
				font-size: 16px; } } }

	.header_contacts_callback {
		margin-left: 15px; }


	.main_header_middle {
		h1 {
			font-size: 34px;
			line-height: 44px; } }


	.question_slider {
		.slide {
			padding: 0 60px; } }

	.owl-nav {
		.owl-prev {
			left: 0px; }
		.owl-next {
			right: 0px; } }


	.sect_callback_question {
		.section_head_block {
			.descr {
				max-width: none; } } }

	.callback_question_form_wrap {
		.callback_question_form_wrap-content {
			padding: 70px 30px 80px; } }


	.main_footer {
		.main_footer_left {
			margin-bottom: 30px; } } }


/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
	/**/

	.logo_block .logo_text > span {
		font-size: 12px; }

	.header_contacts {
		margin-top: 0; }

	.header_contacts_info {
		margin-bottom: 10px; }


	.stage_list {
		.item_wrap {
			margin-bottom: 0; } } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 599px) {
	/**/


	.logo_block {
		.logo_text > span {
			line-height: 20px; } }

	.main_header_top {
		.full-width {
			width: 100%; } }

	.header_contacts {
		margin-top: 30px;
		text-align: center;
		position: relative;
		.header_contacts_callback {
			margin-left: 0;
			position: absolute;
			top: -90px;
			right: 0; } }

	.header_contacts_info {
		.header_contacts_phone {
			font-size: 22px; }
		.header_contacts_email {
			font-size: 20px;
			margin-top: 5px; } }


	.stage_list {
		&::after {
			display: none; }
		.item_wrap {
			margin-bottom: 30px;
			&:nth-child(odd) {
				.item {
					float: none;
					padding-left: 60px;
					&::after {
						display: none; }
					.item_number {
						padding-right: 0px; } } }
			&:nth-child(even) {
				.item {
					padding-right: 0;
					padding-left: 60px;
					float: none;
					text-align: left;
					&::after {
						display: none; }
					.item_number {
						padding-left: 0px; } } } }
		.item {
			width: 100%;
			display: block;
			padding-left: 50px;
			position: relative;
			text-align: left;
			padding: 0;
			> div {
				display: block; }
			.item_number {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				width: 50px;
				text-align: center;
				font-size: 36px; } } }


	.advantages_list {
		.item {
			.item_icon {
				width: 65px; } } }


	footer.main_footer {
		.main_footer_right {
			> div {
				display: block; }
			.footer_contacts_callback {
				float: none;
				margin-top: 25px;
				text-align: center; } } } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 479px) {
	/**/

	.callback_form_popup {
		width: 90%; }

	.header_contacts_callback {
		a {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			.text {
				display: none; }
			.icon {
				display: block;
				border-radius: 50%; } } }

	.main_header_middle h1 {
		font-size: 24px;
		line-height: 30px; }

	.info_icons_block {
		.full-width {
			width: 100%; }
		.item {
			.item_icon {
				height: auto; }
			.item_descr {
				margin-top: 20px; } } }

	.section_head {
		font-size: 32px;
		line-height: 34px; }

	.head {
		&-medium {
			font-size: 30px;
			line-height: 32px; } }


	.owl-nav {
		.owl-prev {
			left: -10px; }
		.owl-next {
			right: -10px; } }

	.question_slider {
		.slide {
			padding: 0 40px; } }


	.default_form {
		.form_footer {
			.button_wrap {
				.default_btn {
					width: 100%; } } } } }


/* Custom, iPhone Retina */
@media only screen and (max-width : 359px) {
	/**/

	.callback_question_form_wrap {
		.callback_question_form_wrap-content {
			padding: 30px 10px 40px; } } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

